import std from './StdLib';
// import multiplayer from './multiplayer';


// Popup Fb
document.querySelectorAll('[data-eventlistener="js-popup-fb"]').forEach(function (linkTag) {
  linkTag.addEventListener('click', (event) => {
      event.preventDefault();
      let url = linkTag.getAttribute("href");
      sp.openPopup(url, 0, 0, 650, 425, 0, 0);
  });
});

// Popup Tw
document.querySelectorAll('[data-eventlistener="js-popup-tw"]').forEach(function (linkTag) {
      linkTag.addEventListener('click', (event) => {
      event.preventDefault();
      let url = linkTag.getAttribute("href");
      sp.openPopup(url, 0, 0, 550, 485, 0, 0);
  });
});

// navigation
let navi;
if (document.getElementById("nav_main")) {
    navi = document.getElementById("nav_main");
    const navi_elements = navi.children;
    if(navi_elements)  {
        for (let i = 0; i < navi_elements.length; i++) {
            let nav_loc = document.location.pathname;
            let nav_part = nav_loc.replace(/^\//, '');
            nav_part = nav_part.replace(/\/.*$/, '');
            if (nav_part === 'en' || nav_part === 'eo') {
                nav_part = 'home'
            }
            let class_name = 'nav_' + nav_part;
            if (navi_elements[i].classList.contains(class_name) ) {
                navi_elements[i].classList.add("selected");
            }
			else {
                navi_elements[i].classList.remove("selected");
			}
        }
    }
}

// typewriter
var typeWriterElement = document.getElementById('js-typewriter');
if(typeWriterElement) {
	var typeWriterText    = typeWriterElement.innerHTML;
	typeWriterElement.innerHTML = '';
	var typeWriterSpeed   = 30;
	var typeWriterCounter = 0;
}
function typeWriterWrite() {
   	if (typeWriterElement && typeWriterCounter < typeWriterText.length) {
    	typeWriterElement.innerHTML += typeWriterText.charAt(typeWriterCounter);
     	typeWriterCounter++;
     	setTimeout(typeWriterWrite, typeWriterSpeed);
    }
}

// loaded
document.addEventListener("DOMContentLoaded", function(event) {
	typeWriterWrite();
});

// modal
function testModal() {
  if(localStorage.getItem("ulisonicLayer") === 'ok' ) {
    modal.style.display = "none";
  }
}
document.addEventListener("DOMContentLoaded", function(event) {
  var modal = document.getElementById("modal");
  var mclose = document.getElementById("modal-close");
  var msave = document.getElementById("modal-save");
  mclose.onclick = function() {
    modal.style.display = "none";
  }
  msave.onclick = function() {
    modal.style.display = "none";
    localStorage.setItem("ulisonicLayer", "ok");
  }
  testModal();
});


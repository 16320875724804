var std = {
	openPopup: function(url, top, left, width, height, withScroll, withResize) {
    	let showScroll = (withScroll==1)? "yes" : "no";
    	let doResize = (withResize==1)? "yes" : "no";
    	let windowName = width + 'x' + height;
    	let windowAttrs = "menubar=no,location=no,directories=no,toolbar=no";
      	windowAttrs += "screenX=" + left + ",screenY=" + top + ",width=" + width + ",height=" + height + ",scrollbars=" + showScroll + ",resizable=" + doResize;
      	let windowPopup = open( url, windowName, windowAttrs);
    	if(windowPopup != null) windowPopup.focus();
  	},

	randColor: function randColor() {
        // let myList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'b', 'c', 'd', 'e', 'f'];
    	let myList = ['a', 'b', 'c', 'd', 'e', 'f'];
    	let myColor = '#';
    	for(let x = 0; x<6; x++) {
        	myColor = myColor + myList[Math.floor(Math.random() * Math.floor(myList.length-1))];
    	}
		// console.log("-> myColor: " + myColor);
    	return myColor;
	},

	setBgColor: function setBgColor() {
    	document.body.style.backgroundColor = this.randColor();
    	// console.info('background-color set to ' + myColor);
	}
};

export { std as default }
